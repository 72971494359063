$primary: #6a1b9a;
$secondary: #7b1fa2;
$tertiary: #8e24aa;
$quaternary: #9c27b0;
$inverted-light: #ba68c8;
$inverted-lighter: #ce93d8;
$white: white;
$dark: #333;
$background: #F4F8F9;
$light-gray: #e6e6e6;
