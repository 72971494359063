// https://engageinteractive.co.uk/blog/em-vs-rem-vs-px
// rems for spaces and sizing em for responsive stuff

$font-family: 'Open Sans', Helvetica, Arial sans-serif;
$font-size-tiny: 0.7rem;
$font-size-small: 0.8rem;
$font-size-medium: 1rem;
$font-size-large: 1.5rem;
$font-size-xlarge: 2rem;

@media screen and (min-width: 320px) {
    html {
      font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
  }
  @media screen and (min-width: 1000px) {
    html {
      font-size: 22px;
    }
  }

