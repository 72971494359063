@import '../styles/typography.scss';
@import '../styles/colors.scss';
@import '../styles/reset.scss';
@import '../styles/mixins.scss';

.graphs {
    font-size: 16px;
    font-family: $font-family;
    /*color: $primary;*/
    width: 100%;
    -webkit-font-smoothing: antialiased;

    &__content {
        /*margin-top: 10px;
        /*margin-left: auto;
     margin-right: auto;
     min-width: 1024px;*/
        width: 100%;
        background: white;
        display: table;
        padding-left: 1rem;
        padding-right: 1rem;
        box-shadow: 0px 0px 6px rgb(0 72 132 / 24%);
        border-radius: 8px;

        ul {
            list-style: none;
            margin-left: 1rem;
        }

        li {
            color: $primary;
            font-weight: normal;
            letter-spacing: 0;
            margin-bottom: 0.6rem;
            font-size: $font-size-small;
            position: relative;

            &::before {
                content: counter(li);
                color: rgba(0, 0, 0, 0);
                background: $secondary;
                border-radius: 50%;
                display: inline-block;
                width: 0.5rem;
                height: 0.5rem;
                margin-left: -1em;
                top: 0.1rem;
                position: absolute;
            }
        }
    }

    &__content-description {
        margin-bottom: 0.5rem;
        margin-left: 0.1rem;
        margin-top: 2rem;
        font-size: $font-size-small;
        letter-spacing: 0;
        font-weight: bold;

    }

    h1 {
        font-family: $font-family;
        font-size: 42px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        text-align: left
    }

    &__content-col {
        position: relative;
        width: 33%;
        float: left;
    }

    h2 {
        font-family: $font-family;
        font-size: 1.5rem;
        //font-size: $font-size-xlarge;
        margin-bottom: 0.7rem;
        color: black;

        b {
            font-size: $font-size-xlarge;
        }
    }

    p {
        font-size: 0.8rem;
        color: black;
        display: inline-block;
        width: 100%;

        b {
            font-size: $font-size-medium;
            font-weight: 500;
            color: grey;

        }
    }

    button {
        float: right;
        /*margin-top: 1rem;*/
    }

    &__button-toolbar-top {
        button {
            float: right;
            margin-right: 5px;
        }
    }

    &__timeline-graph {
        background: rgb(244, 248, 249);
        /*padding: 32px 0;*/
        width: 100%;
        position: relative;
        background: white;
    }

    &__timeline-now {
        position: absolute;
        height: 102%;
        width: 85%;
        left: 15%;

        svg {
            color: black;
        }
    }

    &__datenow-line {
        position: absolute;
        height: 100%;
        width: 1px;
        border-left: 1px dotted gray;
        z-index: 1;
        opacity: 0.5;
    }

    &__arrow-marker {
        position: absolute;
        bottom: 0;
        width: 1rem;
        height: 1rem;
    }

    &__timeline-graph-content {
        position: relative;
        /*max-height: 480px;
         overflow-y: scroll;*/
        margin-top: 2.5rem;
    }

    &__timeline-graph-sponsorlist {
        left: 0;
        width: 15%;
        position: absolute;
        z-index: 3;
    }

    &__timeline-graph-studylist {
        position: relative;
        width: 85%;
        left: 15%;
        background: rgb(244, 248, 249);
        padding: 1rem 0 4rem 0;
    }

    &__timeline-graph-studylist-sponsor {
        width: 100%;
        position: relative;
        /*margin-bottom: 16px;*/

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 3px;
            width: 95%;
            width: 118%;
            left: -18%;
            text-align: center;
            opacity: 0.5;
            /*margin-left: 2.5%;*/
            border-bottom: 1px dotted gray;

        }
    }

    &__timeline-sponsor-label {
        color: black;
        font-size: 11px;
        font-weight: bold;
        height: 100%;
        position: absolute;
        top: 0.5rem;
        left: -17.5%;
        max-width: 8%;
        word-wrap: break-word;
    }

    &__timeline-sponsor-logo {
        position: absolute;
        top: 0.3rem;
        left: -17.5%;
        max-width: 7%;
        max-height: 100%;
    }

    &__timeline-ticks-bottom {
        margin-top: 1.5rem;
        width: 83%;
        position: relative;
        left: 15%;

        span {
            font-size: 12px;
            color: #333;
            font-weight: bold;
        }
    }

    &__timeline-ticks-top {
        /*margin-top: 1.5rem;*/
        width: 83%;
        position: relative;
        left: 15%;

        span {
            font-size: 12px;
            color: #333;
            font-weight: bold;
        }
    }

    &__study-timeline-studyid {
        color: black;
        overflow: hidden;
        font-size: 9px;
        text-decoration: none !important;
    }

    &__study-timeline-item {
        padding-top: 5px;
        background: pink;
        /*border-radius: 15px;*/
        border-radius: 25px;
        //border-radius: 8px;
        color: white;
        max-height: 45px;
        overflow: hidden;
        font-size: 9px;
        text-decoration: none !important;

        &:hover {
            transition: all 130ms ease-out;
            opacity: .8;
        }

        &:link {
            color: white;
        }

        &:visited {
            color: white;
        }

        &:active {
            color: white;
        }

        &--1 {
            background: #bad9e7;
        }

        &--2 {
            background: #67acc5;
        }

        &--3 {
            background: #00185a;
        }

        &--4 {
            background: #0095d5;
        }

        &--escalation {
            background: #0095d5;
        }

        &--Applicable {
            background: pink;
        }

        span {
            display: block;
            margin: .3em 0 0 1em;
            font-size: 1.1em;
            font-weight: 700;
            min-height: 14px;

            &:first-of-type {
                margin-top: 0.7em;
                font-weight: bold;
                min-height: 14px;
            }

            text-decoration: none;
            //position: absolute;
            /*top: 50%;
         left: 50%;
         width: 80%;
         margin: 0 auto;
         transform: translate(-50%, -50%);*/
            overflow: hidden;
            /*max-height: 31%;*/
            max-height: 60%;
            width: 90%;
            word-wrap: break-word;
        }
    }

    &__study-phases {
        text-align: left;
        position: relative;
        left: 15%;
        top: 2rem;

        button {
            float: right;
            margin-right: 5px;
        }
    }

    &__study-timeline-phase {
        background: pink;
        border-radius: 18px;
        color: white;
        height: 2rem;
        display: inline-block;
        margin-right: 0.2rem;
        font-size: 11px;
        line-height: 1.7rem;
        font-weight: bold;
        text-align: center;
        padding: 0 0.8rem;

        &--1 {
            background: #bad9e7;
        }

        &--2 {
            background: #67acc5;
        }

        &--3 {
            background: #00185a;
        }

        &--4 {
            background: #0095d5;
        }

        &--escalation {
            background: #0095d5;
        }

        span {
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            overflow: hidden;
        }
    }

    &__study-timeline-condtion {
        color: black;
        font-size: 11px;
        height: 100%;
        position: absolute;
        word-wrap: break-word;
        left: -9%;
        max-width: 9%;

        overflow: hidden;
        position: relative;

        max-height: 65px;
        /*&:before {
             
             content: '...';
             
             position: absolute;
             
             right: 0;
             bottom: 0;
         }
         &:after {
            
             content: '';
            
             position: absolute;
            
             right: 0;
             width: 1em;
            
             height: 1em;
             margin-top: 0.2em;
             background: white;
           }*/
    }

    &__study-timeline-text {
        color: black;
        max-height: 45px;
        overflow-y: hidden;
        font-size: 11px;

        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            margin: 0 auto;
            transform: translate(-50%, -50%);
            overflow: hidden;
            max-height: 90%;
        }
    }

    &__footer {
        top: 1.2rem;
        min-height: 60px;
        margin-top: 1rem;
        position: relative;
        margin-bottom: 1rem;
        text-align: left;

        p {
            font-size: 10px;
            line-height: 11px;
        }
    }
}